import { navigate } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { adHelper } from "../lib/ad_helper"

export const AgentIntro = ({ agent }) => (
  <div
    onClick={() => navigate(adHelper.agentUrl(agent.name))}
    className="item our-team-slider-item clearfix"
    data-aos="fade-up"
  >
    <a
      href={adHelper.agentUrl(agent.name)}
      className="our-team-slider-item-inner clearfix d-table w-100 text-decoration-none"
    >
      <div className="our-team-slider-item-img-holder clearfix">
        <img
          src={`/images/agents/large/${agent.name}.jpg`}
          alt="our-team"
          className="object-fit-cover h-100 w-100"
        />
      </div>
      <div className="our-team-slider-item-cntnt-holder clearfix">
        <div className="our-team-slider-item-cntnt-holder-inner clearfix">
          <h5 className="text-black font-30 font-AvenirLTStd-Medium font-weight-bold">
            {agent.name}
          </h5>
          <p className="font-24 opacity-0-7 text-black font-AvenirLTStd-Roman">
            {agent.role}
          </p>
          <a className="font-AvenirLTStd-Medium properties-slider-1-link" href={adHelper.agentUrl(agent.name)}>Browse Properties
          </a>
        </div>
      </div>
    </a>
  </div>
)
AgentIntro.propTypes = {
  agent: PropTypes.any,
}
