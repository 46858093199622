import React from "react"
import { data } from "../lib/data"
import { AgentIntro } from "./AgentIntro";

export const TeamSlider = () => (
  <div className="section our-team-slider-section clearfix pt-3 pb-5">
    <div className="container clearfix">
      <div className="row clearfix align-items-end">
        <div className="col-md-9 clearfix">
          <div className="title-1-holder clearfix">
            <h2
              className="title-1 text-uppercase aos-init aos-animate"
              data-aos="fade-in"
            >
              <span className="text-black-50">Our </span>Team
            </h2>
          </div>
        </div>
        <div className="col-md-3 clearfix text-md-right">
          <div className="owl-nav our-team-slider-1-nav d-inline-flex flex-wrap align-items-center float-right mr-n2">
            <button
              type="button"
              className="owl-prev our-team-slider-1-prev bg-transparent border-0 px-2"
            >
              <img
                src="/images/long-arrow-left-black.png"
                alt="HTML Template"
              />
            </button>
            <button
              type="button"
              className="owl-next our-team-slider-1-next bg-transparent border-0 px-2"
            >
              <img
                src="/images/long-arrow-right-black.png"
                alt="HTML Template"
              />
            </button>
          </div>
        </div>
      </div>
      <div className="our-team-slider-holder clearfix mt-5">
        <div className="our-team-slider-1 owl-carousel owl-theme">
          {Object.keys(data.agentDetails).map(name => (
            <AgentIntro key={name} agent={Object.assign({}, { name }, data.agentDetails[name])} />
          ))}
        </div>
      </div>
    </div>
  </div>
)


