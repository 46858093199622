import React from "react"
import { Layout } from "../../components/Layout"
import { AgentBanner } from "../../components/AgentBanner"
// import { OurTeam } from '../../components/OurTeam';
// import { OurApproach } from '../../components/OurApproach';
// import { TeamIntro } from "../../components/TeamIntro"
import { TeamSlider } from '../../components/TeamSlider';
import { data } from "../../lib/data";
// import { JoinTeam } from '../../components/JoinTeam';
// import { ContactRow } from '../../components/ContactRow';
// import { AgentTestimonials } from "../../components/AgentTestimonials"

export default function Agents() {
  return (
    <Layout className="our-team-page">
      <AgentBanner email={data.userProfile.userEmail} phone={data.userProfile.phone} />
      {/* <OurTeam /> */}
      {/* <OurApproach /> */}
      {/* <TeamIntro /> */}
      <TeamSlider />
      {/* <JoinTeam /> */}
      {/* <ContactRow /> */}
      {/* <AgentTestimonials /> */}
    </Layout>
  )
}

